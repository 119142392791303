import React, { useState } from 'react';
import { Grid } from '@material-ui/core';

import PaginationWithCustomRows from '../../components/PaginationWithCustomRows';
import PageLoader from '../../components/PageLoader';
import Table from './Table';
import Modal from './Modal';
import FiltersForm from './FiltersForm';

const Questions = ({ handleSave, options, fullInfoLoading, table, pagination, filter }) => {
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const { initialFilterDefinitions, handleFilter, initialFilterValues, selectedPage, selectedSection } = filter;
  const { total, itemsPerPage, currentPage, handleChangePage, handleRowAmountChange } = pagination;
  const { list, updateList, loading } = table;

  return fullInfoLoading ? (
    <PageLoader />
  ) : (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FiltersForm
            initialFilterDefinitions={initialFilterDefinitions}
            handleOnChange={handleFilter}
            initialValues={initialFilterValues}
            selectedPage={selectedPage}
            selectedSection={selectedSection}
          />
        </Grid>
        <Grid item xs={12}>
          <Table
            loading={loading}
            list={list}
            updateList={updateList}
            handleModal={item => {
              setModal(true);
              setModalData(item);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <PaginationWithCustomRows
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            total={total}
            onPageChange={handleChangePage}
            onRowAmountChange={handleRowAmountChange}
          />
        </Grid>
      </Grid>
      <Modal
        open={modal}
        options={options}
        data={modalData}
        handleClose={() => setModal(false)}
        handleClear={() => setModalData(null)}
        handleSave={handleSave}
      />
    </Grid>
  );
};

export default Questions;
