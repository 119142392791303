/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';

import { currentCompany } from '../../../actionTypes';
import { title } from '../../routes/path';
import QuestionsContainer from '../../containers/Questions';
import { scores, visibilityTypes } from '../../../utils/enums/questions';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';
import { formatPageTitle } from '../../../utils/textFormaters';
import { skipFalsyValues } from '../../../utils/pageHelpers';
import { contractorLevels, contractorLevelPathEnum } from '../../../utils/constants';

const Questions = () => {
  const dispatch = useDispatch();
  const { id, contractorLevel: contractorLevelPath } = useParams();
  const { companyId, companyName } = useCompanyInfo(id);
  const location = useLocation();

  const { loading, list, updateList, total, filterDefinitions, fullInfoLoading, options } = useSelector(
    state => state.currentCompany.questions,
  );

  const contractorLevel =
    Object.keys(contractorLevelPathEnum).filter(item => contractorLevelPathEnum[item] === contractorLevelPath)[0] ||
    null;

  const {
    itemsPerPage = '50',
    currentPage = '1',
    questionTitle = '',
    section = '',
    page = '',
    questionType = '',
    withScores = '',
    visibility = '',
  } = qs.parse(location.search, { comma: true });

  const filterValues = {
    questionTitle,
    section,
    page,
    questionType,
    withScores: withScores === scores.All ? '' : withScores,
    visibility: visibility === visibilityTypes.All ? '' : visibility,
  };

  useEffect(() => {
    const payload = {
      pagination: {
        page: currentPage,
        perPage: itemsPerPage,
      },
      filter: { ...filterValues, contractorLevel },
    };

    dispatch({
      type: currentCompany.questions.get.start,
      payload: { companyId, payload, contractorLevel, fullInfo: Object.keys(filterDefinitions).length === 0 },
    });
  }, [
    companyId,
    contractorLevel,
    dispatch,
    currentPage,
    itemsPerPage,
    questionTitle,
    section,
    page,
    questionType,
    withScores,
    visibility,
  ]);

  const handleSave = (id, values) =>
    dispatch({
      type: currentCompany.questions.update.start,
      payload: { companyId, contractorLevel, questionId: id, values },
    });

  const handleChangePage = offset => {
    const currentPage = offset / itemsPerPage + 1;

    dispatch(
      push({
        pathname: location.pathname,
        search: qs.stringify(
          { itemsPerPage, currentPage, ...skipFalsyValues(filterValues) },
          { encode: false, skipNull: true },
        ),
      }),
    );
  };

  const handleRowAmountChange = value => {
    dispatch(
      push({
        pathname: location.pathname,
        search: qs.stringify(
          { itemsPerPage: value, currentPage: 1, ...skipFalsyValues(filterValues) },
          { encode: false, skipNulls: true },
        ),
      }),
    );
  };

  const handleFilter = function(values) {
    dispatch(
      push({
        pathname: location.pathname,
        search: qs.stringify(
          { itemsPerPage, currentPage: 1, ...skipFalsyValues(values) },
          { encode: false, skipNulls: true },
        ),
      }),
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {formatPageTitle(title.private.company.questions, {
            companyName,
            contractorLevel: contractorLevels[contractorLevel],
          })}
        </title>
      </Helmet>
      <QuestionsContainer
        fullInfoLoading={fullInfoLoading}
        handleSave={handleSave}
        options={options}
        table={{
          list,
          updateList,
          loading,
        }}
        filter={{
          initialFilterDefinitions: filterDefinitions,
          initialFilterValues: filterValues,
          selectedPage: page,
          selectedSection: section,
          handleFilter,
        }}
        pagination={{
          total,
          itemsPerPage,
          currentPage,
          handleChangePage,
          handleRowAmountChange,
        }}
      />
    </>
  );
};

export default Questions;
