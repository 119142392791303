import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link as MaterialLink,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Collapse,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import useStyles from './styles';

const GradeModal = ({ open, handleClose, gradeLetter, list, handleDownloadGrade, loadingData, loading }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paper: classes.dialogModal,
      }}
    >
      <Collapse in={loadingData}>
        <Grid item xs={12}>
          <Grid container alignItems="center" justify="center" className={classes.loadingContainer}>
            <CircularProgress />
          </Grid>
        </Grid>
      </Collapse>

      <Collapse in={!loadingData}>
        <DialogTitle
          disableTypography
          classes={{
            root: classes.titleWrapper,
          }}
        >
          {gradeLetter ? (
            <Typography variant="subtitle1">
              <strong>Unofficial Grade</strong> : {gradeLetter}
            </Typography>
          ) : (
            <Typography variant="subtitle1">Grade</Typography>
          )}
        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.contentWrapper,
          }}
        >
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Question title</TableCell>
                <TableCell>Score</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list
                .filter(item => item.maxScore !== item.score)
                .map(item => (
                  <TableRow key={item.pqfFieldName}>
                    <TableCell>{item.pqfFieldName}</TableCell>
                    <TableCell>{item.grade}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions
          classes={{
            root: classes.contentWrapper,
          }}
        >
          <Grid item xs={12}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={6}>
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <MaterialLink onClick={() => handleDownloadGrade(list)} className={classes.downloadLink}>
                    <GetAppIcon />
                    <span> Download full scorecard </span>
                  </MaterialLink>
                )}
              </Grid>
              <Grid item>
                <Button variant="contained" size="small" onClick={handleClose}>
                  Close
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Collapse>
    </Dialog>
  );
};

export default GradeModal;
