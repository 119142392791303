import React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';

import useStyles from './style';
import QuestionItem from '../QuestionItem';

const QuestionTable = ({ list, editable, updateList, downloadList, handleUpdate, handleDownload }) => {
  const classes = useStyles();
  return (
    <Table className={classes.tableStyles}>
      <TableBody>
        {Object.keys(list).map(section => (
          <React.Fragment key={section}>
            <TableRow className={classes.header}>
              <TableCell colSpan={3}>{list[section].sectionName}</TableCell>
            </TableRow>
            {list[section].questions.map(item => (
              <QuestionItem
                {...item}
                editable={editable}
                key={item.id}
                updateList={updateList}
                downloadList={downloadList}
                sectionClass={classes.section}
                questionClass={classes.question}
                handleUpdate={handleUpdate}
                handleDownload={handleDownload}
              />
            ))}
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  );
};

export default QuestionTable;
