import { createEnum, requestState } from '../utils/actionTypesHelpers';

export default createEnum(
  {
    companyInfo: {
      get: createEnum(requestState, 'currentCompany.companyInfo.get'),
      validation: createEnum(requestState, 'currentCompany.companyInfo.validation'),
      updateConditions: createEnum({ update: null }, 'currentCompany.companyInfo.updateConditions'),
    },
    workCategory: {
      get: createEnum(requestState, 'currentCompany.workCategory.get'),
      update: createEnum(requestState, 'currentCompany.workCategory.update'),
    },
    service: {
      get: createEnum(requestState, 'currentCompany.service.get'),
      update: createEnum(requestState, 'currentCompany.service.update'),
    },
    finInfo: {
      get: createEnum(requestState, 'currentCompany.finInfo.get'),
      update: createEnum(requestState, 'currentCompany.finInfo.update'),
    },
    user: {
      get: createEnum(requestState, 'currentCompany.user.get'),
      create: createEnum(requestState, 'currentCompany.user.create'),
      resend: createEnum(requestState, 'currentCompany.user.resend'),
      update: createEnum(requestState, 'currentCompany.user.update'),
      remove: createEnum(requestState, 'currentCompany.user.remove'),
    },
    reference: {
      create: createEnum(requestState, 'currentCompany.reference.create'),
      delete: createEnum(requestState, 'currentCompany.reference.delete'),
      get: createEnum(requestState, 'currentCompany.reference.get'),
      update: createEnum(requestState, 'currentCompany.reference.update'),
      updateJobInfo: createEnum(requestState, 'currentCompany.reference.updateJobInfo'),
    },
    parentCompany: {
      create: createEnum(requestState, 'currentCompany.parentCompany.create'),
      get: createEnum(requestState, 'currentCompany.parentCompany.get'),
      update: createEnum(requestState, 'currentCompany.parentCompany.update'),
    },
    organizationInfo: {
      get: createEnum(requestState, 'currentCompany.organizationInfo.get'),
      update: createEnum(requestState, 'currentCompany.organizationInfo.update'),
    },
    officer: {
      get: createEnum(requestState, 'currentCompany.officer.get'),
      update: createEnum(requestState, 'currentCompany.officer.update'),
      create: createEnum(requestState, 'currentCompany.officer.create'),
    },
    yesNoQuestions: {
      get: createEnum(requestState, 'currentCompany.yesNoQuestions.get'),
      update: createEnum(requestState, 'currentCompany.yesNoQuestions.update'),
    },
    healthProf: {
      get: createEnum(requestState, 'currentCompany.healthProf.get'),
      update: createEnum(requestState, 'currentCompany.healthProf.update'),
    },
    insurancePolicy: {
      create: createEnum(requestState, 'currentCompany.insurancePolicy.create'),
      update: createEnum(requestState, 'currentCompany.insurancePolicy.update'),
      get: createEnum(requestState, 'currentCompany.insurancePolicy.get'),
    },
    info: {
      get: createEnum(requestState, 'currentCompany.info.get'),
      update: createEnum(requestState, 'currentCompany.info.update'),
      updateImage: createEnum(requestState, 'currentCompany.info.updateImage'),
      deleteImage: createEnum(requestState, 'currentCompany.info.deleteImage'),
    },
    emrate: {
      get: createEnum(requestState, 'currentCompany.emrate.get'),
      update: createEnum(requestState, 'currentCompany.emrate.update'),
    },
    ownerAddendum: {
      get: createEnum(requestState, 'currentCompany.ownerAddendum.get'),
      create: createEnum(requestState, 'currentCompany.ownerAddendum.create'),
    },
    injuryIllness: {
      get: createEnum(requestState, 'currentCompany.injuryIllness.get'),
      update: createEnum(requestState, 'currentCompany.injuryIllness.update'),
    },
    craftsman: {
      get: createEnum(requestState, 'currentCompany.craftsman.get'),
      update: createEnum(requestState, 'currentCompany.craftsman.update'),
    },
    contractorAddendum: {
      get: createEnum({ ...requestState, remove: null, update: null }, 'currentCompany.contractorAddendum.get'),
      create: createEnum(requestState, 'currentCompany.contractorAddendum.create'),
      update: createEnum(requestState, 'currentCompany.contractorAddendum.update'),
      remove: createEnum(requestState, 'currentCompany.contractorAddendum.remove'),
      download: createEnum(requestState, 'currentCompany.contractorAddendum.download'),
    },
    uploadSize: {
      get: createEnum(requestState, 'currentCompany.uploadSize.get'),
      update: createEnum(requestState, 'currentCompany.uploadSize.update'),
    },
    questions: {
      get: createEnum(requestState, 'currentCompany.questions.get'),
      update: createEnum(requestState, 'currentCompany.questions.update'),
    },
    settings: {
      get: createEnum(requestState, 'currentCompany.settings.get'),
      update: createEnum(requestState, 'currentCompany.settings.update'),
        },
    companyAdminInfo: {
        get: createEnum(requestState, 'currentCompany.companyAdminInfo.get'),
        update: createEnum(requestState, 'currentCompany.companyAdminInfo.update'),
    },
  },
  'currentCompany',
);
