/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

const Effect = ({ submitForm, values, debounceFunc }) => {
  const {
    companyName,
    isFavouriteContractor,
    companyType,
    userName,
    workCategories,
    services,
    states,
    zip,
    grades,
    aemin,
    aemax,
    lcmin,
    lcmax,
    bcmin,
    bcmax,
    pqfStatuses,
  } = values;
  useEffect(() => {
    submitForm();
    return () => debounceFunc.cancel();
  }, [
    companyName,
    isFavouriteContractor,
    companyType,
    userName,
    workCategories,
    services,
    states,
    zip,
    grades,
    aemin,
    aemax,
    lcmin,
    lcmax,
    bcmin,
    bcmax,
    submitForm,
    pqfStatuses,
  ]);

  return '';
};

export default Effect;
