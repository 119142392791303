import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from './auth';
import entity from './entity';
import company from './company';
import currentCompany from './currentCompany';
import pages from './pages';
import file from './file';
import filePsmAudit from './filePsmAudit';
import archive from './archive';
import settings from './settings';
import headerNotifications from './header/notifications';
import review from './review';
import addendumFile from './addendumFile';
import workingHours from './workingHours';

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    entity,
    company,
    currentCompany,
    pages,
    file,
    filePsmAudit,
    archive,
    settings,
    headerNotifications,
    review,
    addendumFile,
    workingHours,
  });

export default rootReducer;
