import React, { useState } from 'react';

import GradeModal from '../GradeModal';
import Grade from '../../../components/Grade';

const GradeSystem = ({ grade, list, loading, handleDownloadGrade }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Grade grade={grade} handleClick={() => setModalOpen(true)} />
      <GradeModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        list={list}
        loading={loading}
        handleDownloadGrade={handleDownloadGrade}
      />
    </>
  );
};

export default GradeSystem;
