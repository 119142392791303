import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  firstName: {
    width: '17%',
  },
  lastName: {
    width: '17%',
  },
  emailAddress: {
    width: '23%',
  },
  createDate: {
    width: '13%',
  },
  lastLoginDate: {
    width: '13%',
  },
  actions: {
    width: '13%',
  },
}));

export default useStyles;
