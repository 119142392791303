/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';
import { Helmet } from 'react-helmet';

import { company, workingHours } from '../../../actionTypes';
import { title } from '../../routes/path';
import { userTypes } from '../../../utils/constants';
import { companyRolesSelection } from '../../../utils/constants/companyRoleTypes';
import { skipFalsyValues } from '../../../utils/pageHelpers';
import { useUserPermissions } from '../../../utils/effects/userPermissions';
import refineNumberString from '../../../utils/textFormaters/refineNumberString';
import MarketPlace from '../../containers/MarketPlace';
import { columnEnum } from '../../containers/MarketPlace/CompanyListTabel/columns';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';

const CompanyList = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { companyId } = useCompanyInfo();
  const { type } = useUserPermissions();

  const {
    total,
    items,
    services: allServices,
    workCategories: allWorkCategories,
    loading,
    exporting,
    fullInfoLoading,
    loadingListAddToFavorite,
  } = useSelector(store => store.company.list);
  const { exporting: exportingWorkingHours, canExportWorkingHours } = useSelector(store => store.workingHours.list);
  const companies = useSelector(store => items.map(id => store.entity.company[id]));

  const {
    itemsPerPage = 50,
    currentPage = 1,
    companyName = '',
    isFavouriteContractor = '',
    companyType = '',
    workCategories = [],
    services = [],
    pqfStatuses = [],
    grades = [],
    states = [],
    zip = '',
    aemin = '',
    aemax = '',
    lcmin = '',
    lcmax = '',
    bcmin = '',
    bcmax = '',
    withOverdue = '',
    field = 'companyName',
    order = 'asc',
  } = qs.parse(location.search, { comma: true });

  const noFiltersSelected =
    !companyName &&
    !companyType &&
    !isFavouriteContractor &&
    !workCategories.length &&
    !services.length &&
    !pqfStatuses.length &&
    !states.length &&
    !grades.length &&
    !zip &&
    !aemin &&
    !aemax &&
    !lcmin &&
    !lcmax &&
    !bcmin &&
    !bcmax;

  const filterValues = {
    companyName,
    companyType: companyType === companyRolesSelection.All ? '' : companyType,
    withOverdue,
    isFavouriteContractor,
    workCategories,
    services,
    pqfStatuses,
    grades,
    states,
    zip,
    aemin,
    aemax,
    lcmin: refineNumberString(lcmin),
    lcmax: refineNumberString(lcmax),
    bcmin: refineNumberString(bcmin),
    bcmax: refineNumberString(bcmax),
  };

  useEffect(() => {
    const payload = {
      pagination: {
        page: currentPage,
        perPage: itemsPerPage,
      },
      sort: {
        field,
        order,
      },
      filter: filterValues,
    };
    dispatch({
      type: company.list.start,
      payload: { payload, fullInfo: allWorkCategories.length === 0 || allServices.length === 0 },
    });
  }, [
    currentPage,
    dispatch,
    services.length,
    grades.length,
    workCategories.length,
    companyName,
    companyType,
    isFavouriteContractor,
    aemin,
    aemax,
    lcmin,
    lcmax,
    bcmin,
    bcmax,
    itemsPerPage,
    field,
    order,
    withOverdue,
    pqfStatuses.length,
    states.length,
    zip,
  ]);

  useEffect(() => {
    if (type === userTypes.Owner) {
      dispatch({
        type: workingHours.canExportWorkingHours.start,
        payload: { ownerCompanyId: companyId, isSafetyCouncil: false },
      });
    } else if (type === userTypes.SafetyCouncil) {
      dispatch({
        type: workingHours.canExportWorkingHours.start,
        payload: { isSafetyCouncil: true },
      });
    }
  }, [type]);

  const saveValues = localStorage.getItem('marketplaceColumns');
  const defaultColumn = saveValues ? JSON.parse(saveValues) : Object.keys(columnEnum).map(item => columnEnum[item].key);

  const [visibleColumns, setVisibleColumns] = useState(
    defaultColumn.filter(
      column =>
        !(type === userTypes.SafetyCouncil && [columnEnum.favorite.key, columnEnum.ownerGrade.key].includes(column)),
    ),
  );

  const handleChangePage = offset => {
    const currentPage = offset / itemsPerPage + 1;

    const updatedFilterValues = skipFalsyValues(filterValues);

    dispatch(
      push({
        pathname: location.pathname,
        search: qs.stringify(
          { field, order, itemsPerPage: itemsPerPage, currentPage, ...updatedFilterValues },
          { arrayFormat: 'comma', encode: false, skipNull: true },
        ),
      }),
    );
  };

  const handleFilter = function(values) {
    dispatch(
      push({
        pathname: location.pathname,
        search: qs.stringify(
          { field, order, ...skipFalsyValues(values) },
          { arrayFormat: 'comma', encode: false, skipNulls: true },
        ),
      }),
    );
  };

  const handleExport = () => {
    const payload = {
      sort: {
        field,
        order,
      },
      filter: {
        ...filterValues,
        columns: visibleColumns.filter(x => x !== columnEnum.actions.key),
      },
    };

    dispatch({
      type: company.export.start,
      payload: payload,
    });
  };

  const handleDelete = companyId => {
    const payload = {
      pagination: {
        page: currentPage,
        perPage: itemsPerPage,
      },
      sort: {
        field,
        order,
      },
    };

    dispatch({
      type: company.delete.start,
      payload: { payload, companyId },
    });
  };

  const handleRowAmountChange = value => {
    dispatch(
      push({
        pathname: location.pathname,
        search: qs.stringify(
          { field, order, itemsPerPage: value, currentPage: 1, ...skipFalsyValues(filterValues) },
          { arrayFormat: 'comma', encode: false, skipNulls: true },
        ),
      }),
    );
  };

  const handelSort = (field, order) => {
    dispatch(
      push({
        pathname: location.pathname,
        search: qs.stringify(
          { field, order, ...skipFalsyValues(filterValues) },
          { arrayFormat: 'comma', encode: false, skipNulls: true },
        ),
      }),
    );
  };

  const handleAddToFavorite = async (id, state) => {
    await dispatch({
      type: company.addToFavorite.start,
      payload: { id, companyId, state },
    });
    dispatch({
      type: workingHours.canExportWorkingHours.start,
      payload: { ownerCompanyId: companyId, isSafetyCouncil: false },
    });
  };

  const handleExportWorkingHours = () => {
    dispatch({
      type: workingHours.exportWorkingHours.start,
      payload: {
        userType: type,
        ownerCompanyId: companyId,
        pagination: {
          page: currentPage,
          perPage: itemsPerPage,
        },
        sort: {
          field,
          order,
        },
        filter: filterValues,
        fullInfo: allWorkCategories.length === 0 || allServices.length === 0,
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>{title.private.company.list}</title>
      </Helmet>
      <MarketPlace
        isSafetyCouncil={userTypes.SafetyCouncil === type}
        handleFilter={handleFilter}
        allServices={allServices}
        allWorkCategories={allWorkCategories}
        loading={loading}
        fullInfoLoading={fullInfoLoading}
        companies={companies}
        total={total}
        handleChangePage={handleChangePage}
        noFiltersSelected={noFiltersSelected}
        filterValues={filterValues}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        handleDelete={handleDelete}
        handleRowAmountChange={handleRowAmountChange}
        handelSort={handelSort}
        order={order}
        selectedSortField={field}
        loadingListAddToFavorite={loadingListAddToFavorite}
        handleAddToFavorite={handleAddToFavorite}
        handleExport={handleExport}
        exporting={exporting}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        handleExportWorkingHours={handleExportWorkingHours}
        exportingWorkingHours={exportingWorkingHours}
        canExportWorkingHours={canExportWorkingHours}
      />
    </>
  );
};

export default CompanyList;
