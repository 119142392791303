import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import { FastField, Formik, Field } from 'formik';

import { saveCompanyInfo } from '../../../utils/validate/shemas';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import useStyles from './styles';
import FormCheckbox from '../../components/FormCheckbox';
import usaStates from '../../../utils/constants/usaStates';
import { yesNoOptions } from '../../../utils/constants/formConstats';
import CompanyInfoSubForm from './CompanyInfoSubForm';
import LogoUploadForm from './LogoUploadForm';
import FormNumberInput from '../../components/FormNumberInput';
import LoadingButton from '../../components/LoadingButton';
import { useUserPermissions } from '../../../utils/effects/userPermissions';
import FormBody from '../../components/FormBody';
import PageLoader from '../../components/PageLoader';
import { changeNullToEmptyString, formatDateForInitialValues } from '../../../utils/containersHelpers';
import phone from '../../../utils/textFormaters/phone';
import PromptNotification from '../../components/PromptComponent';
import FileUploaderInput from '../../components/FileUploaderInput';
import FormAutocomplete from '../../components/FormAutocomplete';
import { userTypes, contractorLevelEnum } from '../../../utils/constants';

const formatPhoneValue = obj => {
  const merged = {};
  Object.keys(obj).forEach(key => {
    if (key === 'phoneNumber' || key === 'fax') {
      merged[key] = phone(obj[key]);
    } else {
      merged[key] = obj[key];
    }
  });
  return merged;
};

const handleFormInputAddressChange = (props, e, fieldProps) => {
  const names = fieldProps.name.split('.');
  if (props.values.isMailingAddressTheSame) {
    props.setFieldValue(`mailingAddress.${names[1]}`, e.target.value);
  }
};

const handleAutoCompletedAddressChange = (props, value, fieldProps) => {
  const names = fieldProps.name.split('.');
  if (props.values.isMailingAddressTheSame) {
    props.setFieldValue(`mailingAddress.${names[1]}`, value);
  }
};

const handleCheckboxAddressChange = (props, e, fieldProps) => {
  Object.keys(props.values.streetAddress).forEach(item => {
    if (item === 'id') return;
    if (!fieldProps.value) {
      props.setFieldValue(`mailingAddress.${item}`, props.values.streetAddress[item]);
    } else {
      props.setFieldValue(`mailingAddress.${item}`, '');
    }
  });
};

const CompanyInfoForm = ({
  initialValues,
  companyId,
  requestInProgress,
  loading,
  success,
  error,
  handleSubmit,
  allowUpdate,
}) => {
  const classes = useStyles();
  const { type } = useUserPermissions();
  const isSafetyCouncilUserType = type === userTypes.SafetyCouncil;

  if (loading || (!initialValues.type && !initialValues.mailingAddress)) return <PageLoader />;

  const values = changeNullToEmptyString({ ...initialValues, type: initialValues.level || initialValues.type });
  const formattedInitialValues = formatDateForInitialValues(formatPhoneValue(values));

  const isBasicContractor = formattedInitialValues.type === contractorLevelEnum.BasicContractor;

  const onSubmit = val => {
    const baseType =
      val['type'] === userTypes.Owner ? { type: userTypes.Owner } : { type: userTypes.Contractor, level: val['type'] };
    const clearValues = Object.keys(val).reduce(
      (acc, item) => (['type', 'level', 'typeName'].includes(item) ? acc : { ...acc, [item]: val[item] }),
      baseType,
    );
    handleSubmit(clearValues);
  };

  return (
    <Formik
      initialValues={formattedInitialValues}
      validationSchema={saveCompanyInfo}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {props => (
        <>
          <PromptNotification formStatus={props.dirty} />
          <FormBody loading={loading} disabled={!allowUpdate}>
            <Grid item xs={12} className={classes.contentOverflowWrapper}>
              <Grid container spacing={2}>
                <Grid item lg={6}>
                  <CompanyInfoSubForm
                    names={{
                      type: 'type',
                      name: 'companyName',
                      phoneNumber: 'phoneNumber',
                      fax: 'fax',
                      emailAddress: 'emailAddress',
                      url: 'webSite',
                      taxId: 'taxId',
                      year: 'yearCreated',
                    }}
                    isSafetyCouncilUserType={isSafetyCouncilUserType}
                    isBasicContractor={isBasicContractor}
                  />
                </Grid>
                <Grid container item lg={6} justify="center">
                  <Box className={classes.boxWrapper}>
                    <FastField name="logoLink" allowUpdate={allowUpdate} component={LogoUploadForm} />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg={12} className={classes.contentOverflowWrapper}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item lg={4}>
                          <Typography variant="h5" className={classes.title}>
                            Street Address
                          </Typography>
                        </Grid>
                        <Grid item lg={8}>
                          <div className={classes.labelWrapper}>
                            <Field
                              checkboxClassName={classes.checkboxLeft}
                              name="isMailingAddressTheSame"
                              labelPlacement="end"
                              label={
                                <Typography variant="h5">Mailing Address is the same as street address</Typography>
                              }
                              color="primary"
                              size="medium"
                              handleChange={(e, fieldProps) => handleCheckboxAddressChange(props, e, fieldProps)}
                              component={FormCheckbox}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={4}>
                      <Field
                        className={classes.inputRow}
                        label="Address"
                        name="streetAddress.street"
                        handleChange={(e, fieldProps) => handleFormInputAddressChange(props, e, fieldProps)}
                        component={FormInput}
                      />
                      <Field
                        className={classes.inputRow}
                        label="City"
                        handleChange={(e, fieldProps) => handleFormInputAddressChange(props, e, fieldProps)}
                        name="streetAddress.city"
                        component={FormInput}
                      />
                      <Field
                        className={classes.inputRow}
                        label="State"
                        name="streetAddress.state"
                        id="state"
                        options={usaStates}
                        handleChange={(value, fieldProps) => handleAutoCompletedAddressChange(props, value, fieldProps)}
                        component={FormAutocomplete}
                      />
                      <Field
                        className={classes.inputRow}
                        label="Zip"
                        name="streetAddress.zip"
                        handleChange={(e, fieldProps) => handleFormInputAddressChange(props, e, fieldProps)}
                        maskType="zip"
                        component={FormNumberInput}
                      />
                    </Grid>
                    <Grid item lg={8}>
                      <Grid item lg={6}>
                        <Field
                          className={classes.inputRow}
                          disabled={props.values.isMailingAddressTheSame}
                          label="Address"
                          name="mailingAddress.street"
                          component={FormInput}
                        />
                        <Field
                          className={classes.inputRow}
                          disabled={props.values.isMailingAddressTheSame}
                          label="City"
                          name="mailingAddress.city"
                          component={FormInput}
                        />
                        <Field
                          className={classes.inputRow}
                          label="State"
                          name="mailingAddress.state"
                          id="mailingState"
                          options={usaStates}
                          disabled={props.values.isMailingAddressTheSame}
                          component={FormAutocomplete}
                        />
                        <Field
                          className={classes.inputRow}
                          label="Zip"
                          name="mailingAddress.zip"
                          disabled={props.values.isMailingAddressTheSame}
                          maskType="zip"
                          component={FormNumberInput}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={8}>
                  <Grid container spacing={1}>
                    <Grid item lg={6}>
                      <Typography variant="h5" gutterBottom>
                        Contact Person
                      </Typography>
                      <FastField
                        className={classes.inputRow}
                        textOnly
                        label="First Name"
                        name="contactPersonFirstName"
                        component={FormInput}
                      />
                      <FastField
                        className={classes.inputRow}
                        textOnly
                        label="Last Name"
                        name="contactPersonLastName"
                        component={FormInput}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* hide for owner company type */}
                {Object.keys(contractorLevelEnum).includes(props.values.type) && (
                  <>
                    <Grid item md={8}>
                      <Grid container spacing={1}>
                        <Grid item lg={6}>
                          <Typography variant="h5" gutterBottom>
                            Do you have a State Contractor's License?
                          </Typography>
                          <FastField
                            className={classes.inputRow}
                            name="hasStateContractorLicense"
                            component={FormSelect}
                            options={yesNoOptions}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {props.values.hasStateContractorLicense && (
                      <>
                        <Grid item md={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Field
                                name="contlicense"
                                component={FileUploaderInput}
                                label="Upload State Contractor's License"
                                companyId={companyId}
                                expirationDate
                                disabled={!props.values.hasStateContractorLicense}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={8}>
                          <Grid container spacing={1}>
                            <Grid item lg={6}>
                              <Typography variant="h5" gutterBottom>
                                State and ISO Information
                              </Typography>
                              <Field
                                className={classes.inputRow}
                                label="State Contractor Number"
                                name="stateContractorNumber"
                                component={FormInput}
                                disabled={!props.values.hasStateContractorLicense}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                )}
                <Grid item md={8}>
                  <Grid container>
                    <FastField
                      labelClassName={classes.labelCheckboxRight}
                      name="isoCertified"
                      checked={props.values.isoCertified}
                      label="ISO Certified"
                      color="primary"
                      size="medium"
                      component={FormCheckbox}
                    />
                  </Grid>
                </Grid>
                <Grid item md={8}>
                  <Grid container>
                    <FastField
                      className={classes.textArea}
                      name="description"
                      rows={5}
                      multiline
                      placeholder="Company description"
                      component={FormInput}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {allowUpdate && (
              <LoadingButton
                fixed
                disabled={!props.dirty || !props.isValid}
                loading={requestInProgress}
                success={success}
                error={error}
                onClick={props.handleSubmit}
                text="save"
              />
            )}
          </FormBody>
        </>
      )}
    </Formik>
  );
};

export default CompanyInfoForm;
