import { currentCompany } from '../../actionTypes';

const initialState = {
  users: [],
  loading: false,
  ids: [],
  createSuccess: false,
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case currentCompany.user.create.start:
      return {
        ...state,
        createSuccess: false,
        loading: true,
      };

    case currentCompany.user.create.end:
      return {
        ...state,
        users: [...state.users, action.payload.state],
        createSuccess: true,
        loading: false,
      };

    case currentCompany.user.create.error:
      return {
        ...state,
        createSuccess: false,
        loading: false,
      };

    case currentCompany.user.get.start:
      return {
        ...state,
        loading: true,
      };

    case currentCompany.user.get.end:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };

    case currentCompany.user.resend.start:
      return {
        ...state,
        loading: true,
      };

    case currentCompany.user.resend.end:
      return {
        ...state,
        loading: false,
      };

    case currentCompany.user.update.start:
      return {
        ...state,
        ids: [...state.ids, action.payload.values.id],
      };

    case currentCompany.user.update.end:
      const { id, firstName, lastName } = action.payload;

      return {
        ...state,
        users: state.users.map(user => (user.id === id ? { ...user, firstName, lastName } : user)),
        ids: state.ids.filter(item => item !== id),
      };

    case currentCompany.user.update.error:
      return {
        ...state,
        ids: state.ids.filter(item => item !== action.payload.id),
      };

    case currentCompany.user.remove.start:
      return {
        ...state,
        ids: [...state.ids, action.payload.userId],
      };

    case currentCompany.user.remove.end:
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload.userId),
        ids: state.ids.filter(item => item !== action.payload.userId),
      };

    case currentCompany.user.remove.error:
      return {
        ...state,
        error: action.payload.error,
        ids: state.ids.filter(item => item !== action.payload.userId),
      };

    default:
      return state;
  }
}
