import React, { useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';

import WorkingHoursForm from '../WorkingHoursForm';
import { company } from '../../../actionTypes';
import useStyles from './styles';

const WorkingHoursModal = ({ isOpen, onClose, companyId, dialogProps, ownerId, withSearchByOwner, userType }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { favoriteOwnerList } = useSelector(store => store.company.favoriteOwnerList);

  useEffect(() => {
    if (withSearchByOwner) {
      dispatch({
        type: company.getFavoriteOwnerList.start,
        payload: { contractorId: companyId },
      });
    }
  }, [companyId, dispatch, withSearchByOwner]);

  return (
    <Dialog onClose={onClose} className={classes.container} open={isOpen} maxWidth="xs" fullWidth {...dialogProps}>
      <DialogTitle className={classes.title}>View Working Hours</DialogTitle>
      {onClose && (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
      <DialogContent className={classes.content}>
        <WorkingHoursForm
          ownerList={favoriteOwnerList}
          ownerId={favoriteOwnerList[0]?.id || ownerId}
          contractorId={companyId}
          withSearchByOwner={favoriteOwnerList.length > 0}
          readOnly
          userType={userType}
          withExportWorkingHours
        />
      </DialogContent>
    </Dialog>
  );
};

export default WorkingHoursModal;
